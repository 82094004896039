import { Heading,Text, Box,Button } from '@chakra-ui/react'
import React from 'react'
import {motion} from 'framer-motion'
import { useLocalStorage } from '../hooks/use-localstorage'
import { navigate } from 'gatsby'
import MySeo from '../components/seo' 

const Datenschutz = () => {
    const [name,setName] = useLocalStorage('name',"")
    function ja(){
      setName("Yes-einverstanden")
      navigate("/")
    }
    function nein(){
      setName("Nein-einverstanden")
      navigate("/")
    }
    return(
        <motion.div
        initial={{opacity:0,y:0,paddingBottom:"100px"}}
        animate={{opacity:1,y:20}}
        transition={{duration:.6}}
        >
          <MySeo title="Datenschutz" description='Datenschutzbestimmungen' />
        <Box maxWidth="900" pt={name && name!==""?0:255}>
          <Heading variant="MeH">Datenschutzbestimmungen</Heading>
           <Text mt="4" px="0" fontWeight="600" variant="solid">Datenschutzbeauftragte: <br/>
           Prof. Susanne Enderwitz<br/>
           Email: enderwitz@kulturnetz-oberes-mittelrheintal.org
           </Text>
           <Text variant="solid">Unsere Webseite speichert keine persönliche Daten, verzichtet auf Google Analytics und andere Tools zum Tracking von Nutzern.</Text>
           <Text variant="solid">Von daher können auch keine Daten an Dritte weitergegeben werden.</Text>
           <Text variant="solid">Für die technische Umsetzung einer Webseite werden vom Server rein technische Information benötigt, die aber nicht mit Ihnen persönlich in Beziehung gebracht werden. Diese werden nur temporär gespeichert.</Text>
           <Text variant="solid">Nutzen Sie aber Links von dieser Webseite - für deren Nutzung wir keine persönliche Verantwortung übernehmen - werden Daten ermittelt von dem jeweiligen Service wie Youtube oder Facebook. Über eigene Browsereinstellungen können Sie diese Dienste in ihrem Datenhunger einschränken.</Text>
          
        </Box>
         <Box pt="5">
         <Button bg="blackAlpha.400" variant="ghost"  onClick={()=>ja()}>Gelesen und akzeptiert ...</Button>{" "}
          <Button bg="blackAlpha.400" variant="ghost" onClick={()=>nein()}>Nein ...</Button>
         </Box>

        </motion.div>
    )
}
export default Datenschutz
